import "./style.scss";
import Alpine from "alpinejs";

import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

import noUiSlider from "nouislider";
import "nouislider/dist/nouislider.css";

window.gsap = gsap;
window.Alpine = Alpine;
window.noUiSlider = noUiSlider;

import "./alpineInit.js";
import "./fetchOrders.js";
// import "./interaction.js" // not in use

console.log("loaded main");

Alpine.start();
