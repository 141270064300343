export async function processOrders() {
    console.log("processOrders");
    const store = Alpine.store("refueat");
    console.log(store.curShop.name);

    if (store.currentlyProcessingOrdersFor == store.curShop.name) {
        console.log("abort processOrders: already processing for this shop");
        return;
    }

    store.currentlyProcessingOrdersFor = store.curShop.name;
    store.loading = true;

    // if latest request was less than 10m (9m) ago, we dont fetch anything
    let now = new Date();
    if (now - new Date(store.lastRequestFor[store.curShop.url]) < 540000) {
        console.log("abort processOrders: data is not old");
        // we mightve switched shops, so we call applyFilters to refresh calendar entries!
        if ("applyFilters" in window) {
            applyFilters();
        }
        store.currentlyProcessingOrdersFor = null;
        store.loading = false;
        return;
    }

    // if we fetch, we reset filters to avoid visualization bugs
    let inputs = document.querySelectorAll("form#filters input");
    for (const input of inputs) {
        input.checked = false;
    }
    if ("applyFilters" in window) {
        applyFilters();
    }

    // fetch the product ids for products with personnel/dishes ONCE - static
    let serviceProducts_url;
    if (!store.personnelOrDishIds[store.curShop.url])
        store.personnelOrDishIds[store.curShop.url] = [];
    if (!store.personnelOrDishIds[store.curShop.url].length) {
        serviceProducts_url =
            "https://" +
            store.curShop.url +
            "/wp-json/wc/v3/products?per_page=100&category=70&_fields=id&parent_exclude=1841&exclude=1841";
    }

    // fetch the product ids for products with drinks/hot dishes ONCE - static
    let hotProducts_url;
    if (!store.hotOrDrinkIds[store.curShop.url])
        store.hotOrDrinkIds[store.curShop.url] = [];
    if (!store.hotOrDrinkIds[store.curShop.url].length) {
        hotProducts_url =
            "https://" +
            store.curShop.url +
            "/wp-json/wc/v3/products?per_page=100&category=47,71&_fields=id";
    }

    function handleHotProductsResponse(response) {
        response = response.map((e) => e.id);
        store.hotOrDrinkIds[store.curShop.url] = response;
    }
    function handleServiceProductsResponse(response) {
        response = response.map((e) => e.id);
        store.personnelOrDishIds[store.curShop.url] = response;
    }

    const requests = [];

    if (hotProducts_url)
        requests.push({
            url: hotProducts_url,
            handler: handleHotProductsResponse,
        });
    if (serviceProducts_url)
        requests.push({
            url: serviceProducts_url,
            handler: handleServiceProductsResponse,
        });

    // Map each request to a promise and use Promise.allSettled to fetch all data
    Promise.allSettled(
        requests.map((request) => fetchAllPages(request.url)),
    ).then((results) => {
        results.forEach((result, index) => {
            // Call the corresponding handler for this request's response
            requests[index].handler(result.value);
        });
    });

    // TODO change fetch request logic in refueat-theme??
    // get ALL orders from next 100 days
    let url =
        "https://" +
        store.curShop.url +
        "/wp-json/wc/v3/orders?status=processing,completed,angebot,angebotsanfrage,unpaid-invoice&per_page=50&order_date=";

    // get orders for last 30 and next 180 days
    for (let i = -30; i <= 180; i++) {
        let newDateObj = new Date(new Date().setDate(new Date().getDate() + i));
        let iso = newDateObj.toISOString().slice(0, 10);
        url += iso + ",";
    }
    url = url.substring(0, url.length - 1); // remove last comma

    let response = await fetchAllParallel(url); // optimized the orders call
    response = await transformJsonToAlpine(response);
    store.lastRequestFor[store.curShop.url] = new Date();
    store.currentlyProcessingOrdersFor = null;
    store.loading = false;
}

// helper to take care of multiple page fetch
async function fetchAllPages(url) {
    const store = Alpine.store("refueat");

    let curPage = 1;
    let totalPages = null;
    let totalResponse = [];

    do {
        await fetch(`${url}&page=${curPage}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + store.auth.jwts[store.curShop.url],
            },
        })
            .then((response) => {
                if (!totalPages) {
                    totalPages = parseInt(
                        response.headers.get("x-wp-totalpages"),
                        10,
                    );
                }
                return response.json();
            })
            .then(
                (response) => (totalResponse = totalResponse.concat(response)),
            )
            .catch((err) => console.error(err));

        curPage++;
    } while (curPage <= totalPages);

    return totalResponse;
}

async function fetchData(url) {
    const store = Alpine.store("refueat");
    try {
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + store.auth.jwts[store.curShop.url],
            },
        });
        const data = await response.json();
        return { headers: response.headers, data };
    } catch (error) {
        console.error("There was an error fetching the data:", error);
        throw error; // Rethrow the error to be handled by Promise.all
    }
}

async function fetchAllParallel(url) {
    let curPage = 1;
    let defaultPages = 7;
    let totalResponse = [];

    let urls = [];

    while (curPage <= defaultPages) {
        urls.push(`${url}&page=${curPage}`);
        curPage++;
    }

    // Start all fetch requests in parallel
    let fetchPromises = urls.map(fetchData);

    // Use Promise.race to get the first resolved response
    const firstResponse = await Promise.race(fetchPromises);
    const totalPages = firstResponse.headers.get("x-wp-totalpages");
    const additionalPages = totalPages - defaultPages;

    if (additionalPages > 0) {
        // Add additional fetch promises if more pages are needed
        let newUrls = [];
        while (curPage <= totalPages) {
            newUrls.push(`${url}&page=${curPage}`);
            curPage++;
        }
        const additionalPromises = newUrls.map(fetchData);
        fetchPromises = fetchPromises.concat(additionalPromises);
    }

    // Use Promise.allSettled to get all responses
    const allSettledPromises = await Promise.allSettled(fetchPromises);

    allSettledPromises.forEach((result, index) => {
        if (result.status === "fulfilled") {
            // console.log(`Data from ${urls[index]}:`, result.value.data);
            // Handle the successful response here
            totalResponse = totalResponse.concat(result.value.data);
        } else if (result.status === "rejected") {
            console.error(`Error fetching from ${urls[index]}:`, result.reason);
            // Handle the error here
        }
    });
    return totalResponse;
}

async function transformJsonToAlpine(data) {
    const store = Alpine.store("refueat");
    const shop = store.curShop;

    // if store has no data at all, initialize it
    if (!store.orderData[shop.url]) store.orderData[shop.url] = {};

    // init stuff that we dont have!
    if (!store.hiddenIds[store.curShop.url])
        store.hiddenIds[store.curShop.url] = [];
    if (!store.nameIds[store.curShop.url])
        store.nameIds[store.curShop.url] = [];

    // loop through fetched orders
    for (const id in data) {
        const order = data[id];

        // find day and corresponding orderData array
        let date;
        if (order.delivery_type == "delivery") {
            date = order.meta_data.find((m) => m.key == "delivery_date").value;
        } else {
            date = order.meta_data.find((m) => m.key == "pickup_date").value;
        }
        let ordersForTheDate;

        // if store has no data for the found date, initialize it
        if (!store.orderData[shop.url][date])
            store.orderData[shop.url][date] = [];

        ordersForTheDate = store.orderData[shop.url][date];

        let existingOrder = null;
        existingOrder = ordersForTheDate.find((o) => o.id == order.id);

        // check whether found order was modified, if so replace
        if (
            existingOrder &&
            existingOrder.modifiedAt != order.date_modified_gmt
        ) {
            console.log("an order has changed."); // this currently always gets displayed once, idk why but it doesnt seem to matter
            ordersForTheDate = ordersForTheDate.filter((o) => o.id != order.id);

            existingOrder = null;
        }

        if (!existingOrder) {
            let orderEntry = {}; //ordersForTheDate[ordersForTheDate.length - 1];
            // save order information
            orderEntry.menus = order.line_items;
            orderEntry.id = order.id;
            orderEntry.status = order.status;

            const s = order.shipping;
            const b = order.billing;
            orderEntry.shippingAddress = `${s.address_1} <br> ${s.postcode} ${s.city}`;
            orderEntry.company = b.company || "";

            orderEntry.phone = b.phone || "";
            orderEntry.customerName =
                (s.first_name || "") + " " + (s.last_name || "");
            if (orderEntry.customerName == " ")
                orderEntry.customerName =
                    (b.first_name || "") + " " + (b.last_name || ""); // see if name is in billing instead
            orderEntry.customerNote = order.customer_note || "";
            orderEntry.orderNumber = order.number;
            orderEntry.time = getTimeFromOrder(order);
            orderEntry.shippingMethod = getDeliveryNameFromOrder(order);
            orderEntry.paymentMethod = order.payment_method_title;
            orderEntry.total =
                order.total.replace(".", ",") + order.currency_symbol;
            orderEntry.distance = "";
            orderEntry.createdAt = order.date_created_gmt;
            orderEntry.modifiedAt = order.date_modified_gmt;
            orderEntry.totalValue = Number(order.total);
            orderEntry.totalNetValue =
                Number(order.total) - Number(order.total_tax);
            orderEntry.totalNet =
                orderEntry.totalNetValue.toFixed(2).replace(".", ",") +
                order.currency_symbol;

            // add google maps info
            let order_distance = order.meta_data.find(
                (m) => m.key == "delivery_distance",
            );
            orderEntry.distance = order_distance ? order_distance.value : "";
            let order_bearing = order.meta_data.find(
                (m) => m.key == "delivery_bearing",
            );
            orderEntry.bearing = order_bearing ? order_bearing.value : "";

            // directions link
            let parts = orderEntry.shippingAddress.split(" <br> ");
            let formatted =
                parts[1].replace(/\s+/g, "+") +
                ",+" +
                parts[0].replace(/\s+/g, "+");
            orderEntry.directionsLink =
                "https://www.google.com/maps/dir/Friedhofsweg+1,+12529+Schönefeld/" +
                formatted +
                "/";

            // find out if there are updated versions of this order
            let originalAngebotOrderId = order.meta_data.find(
                (m) => m.key == "original_angebot_order_id",
            );
            orderEntry.originalAngebotOrderId = originalAngebotOrderId
                ? originalAngebotOrderId.value
                : "";
            let previousOrderId = order.meta_data.find(
                (m) => m.key == "previous_order_id",
            );
            orderEntry.previousOrderId = previousOrderId
                ? previousOrderId.value
                : "";

            if (!!orderEntry.originalAngebotOrderId) {
                store.hiddenIds[store.curShop.url].push(
                    orderEntry.originalAngebotOrderId,
                );
            } else if (!!orderEntry.previousOrderId) {
                store.hiddenIds[store.curShop.url].push(
                    orderEntry.previousOrderId,
                );
            }
            orderEntry.isDisplayed = true;

            // set flags for filters
            orderEntry.foodbike = false;
            orderEntry.service = false;
            orderEntry.hot = false;
            // orderEntry.highPrice = false;
            // check for foodbike/personnel/dishes in menus
            for (const menu of orderEntry.menus) {
                if (menu.name.includes("Foodbike")) orderEntry.foodbike = true;
                if (
                    store.personnelOrDishIds[store.curShop.url].includes(
                        menu.product_id,
                    )
                )
                    orderEntry.service = true;
                if (
                    store.hotOrDrinkIds[store.curShop.url].includes(
                        menu.product_id,
                    )
                )
                    orderEntry.hot = true;
            }
            ordersForTheDate.push(orderEntry);
            // if (orderEntry.totalValue > 500) orderEntry.highPrice = true;
        }
    }

    // set all outdated orders to invisible
    for (const day in store.orderData[shop.url]) {
        for (const order of store.orderData[shop.url][day]) {
            const isHidden =
                store.hiddenIds[store.curShop.url].findIndex(
                    (i) => i == order.id,
                ) >= 0;
            if (isHidden) {
                order.isDisplayed = false;
            }
        }
    }

    // sort orderdata - processing first and within sorted by time
    for (const day in store.orderData[shop.url]) {
        const elements = store.orderData[shop.url][day];
        elements.sort((a, b) => {
            if (a.status == "processing" && b.status != "processing") return -1;
            if (a.status != "processing" && b.status == "processing") return 1;
            if (a.status == "processing" && b.status == "processing") {
                if (a.time < b.time) return -1;
                else return 1;
            } else {
                if (a.time < b.time) return -1;
                else return 1;
            }
        });
    }

    // store.filteredOrderData = JSON.parse(
    //     JSON.stringify(store.orderData[shop.url]),
    // );

    // let minPrice = 1000;
    // let maxPrice = 0;
    // for (const date in store.orderData[store.curShop.url]) {
    //     for (const entry of store.orderData[store.curShop.url][date]) {
    //         if (entry.totalValue > maxPrice) maxPrice = entry.totalValue;
    //         if (entry.totalValue < minPrice) minPrice = entry.totalValue;
    //     }
    // }
    // store.maxPrice = maxPrice;
    // store.minPrice = minPrice;

    // //TODO: this is not really working
    // window.rangeSlider.noUiSlider.updateOptions({
    //     range: {
    //         min: Math.floor(store.minPrice),
    //         max: Number(Math.ceil(store.maxPrice)),
    //         "20%": 200,
    //         "80%": store.maxPrice > 5000 ? 5000 : store.maxPrice,
    //     },
    // });

    // setTimeout(() => {
    //     window.rangeSlider.noUiSlider.set([
    //         Math.floor(store.minPrice),
    //         Number(Math.ceil(store.maxPrice)),
    //     ]);

    // }, 500);
    applyFilters();

    return store.orderData[shop.url];
}

// not called
function processProductTable(
    varId,
    productTable,
    product,
    orderEntry,
    variationQty = 1,
) {
    const store = Alpine.store("refueat");

    let allParts = JSON.parse(productTable.replaceAll(`\\\"`, `"`));
    for (const index in allParts) {
        const part = allParts[index];
        const id = varId;
        const shorthand = part.product;
        const size = part.size;

        if (!store.nameIds[shorthand]) {
            // if the name occurred for the first time, we add the object for it and push the id
            store.nameIds[shorthand] = [];
            store.nameIds[shorthand].push([id, size]);
        } else {
            // if the object exists we add the id to the array, if it wasnt there yet
            // check for array in array of arrays hack
            let arr1 = JSON.stringify(store.nameIds[shorthand]);
            let arr2 = JSON.stringify([id, size]);
            if (arr1.indexOf(arr2) == -1) {
                store.nameIds[shorthand].push([id, size]);
            }
        }
        if (!orderEntry.menus[shorthand]) {
            orderEntry.menus[shorthand] = {};
        }
        if (!orderEntry.menus[shorthand][size])
            orderEntry.menus[shorthand][size] = 0;
        // quantity consist of: amount of orders for this product * amount of sub-products within this product * amount of a single sub-product
        // example-order: 2x Catering für 50
        // amount of orders: 2x
        // amount of sub-products (pitabrot): 100x 1er Variation
        // amount of single sub-product: 1x in 1er Variation
        //      => 200x
        orderEntry.menus[shorthand][size] +=
            parseInt(part.qty) * product.quantity * variationQty;
    }
}

// not called
function setupProductsAndTotals(datesOrders) {
    const store = Alpine.store("refueat");

    // add orderQuantities in the the structure of
    // {
    // 	"7893": {
    // 	  "M": 	1,
    // 	  "XL": 2
    // 	}
    // }
    // to productQuantities per product

    for (const date in datesOrders) {
        const orders = datesOrders[date];
        if (!store.productQuantities[date]) {
            store.productQuantities[date] = {};
        }
        let dateQuantities = store.productQuantities[date];
        for (const order of orders) {
            for (const shorthand in order.menus) {
                const product = order.menus[shorthand];

                let found = shorthand in dateQuantities;
                if (!found) {
                    let p = {
                        orders: {},
                    };
                    p.orders[order.orderNumber] = product;
                    dateQuantities[shorthand] = p;
                } else {
                    if (!dateQuantities[shorthand].orders[order.orderNumber])
                        dateQuantities[shorthand].orders[order.orderNumber] =
                            product;
                }
            }
        }
    }

    // add totals object
    for (const date in datesOrders) {
        let dateQuantities = store.productQuantities[date];
        for (let shorthand in dateQuantities) {
            const product = dateQuantities[shorthand];
            product.totalQtys = {};
            for (const orderNumber in product.orders) {
                for (const size in product.orders[orderNumber]) {
                    const sizeQty = product.orders[orderNumber][size];
                    if (size in product.totalQtys) {
                        product.totalQtys[size] += sizeQty;
                    } else {
                        product.totalQtys[size] = sizeQty;
                    }
                }
            }
        }
    }

    // not needed?
    // sort into array so that each order is filled before next is listed (old sorting logic)
    // let arr = Object.entries(store.productQuantities)
    // let sortedArr = []
    // for (const order of store.orderData[shop.url]) {
    // 	let orderNo = order.orderNumber
    // 	let subArr = []
    // 	for (const [pShorthand, pEntry] of arr) {
    // 		if (Object.keys(pEntry.orders).includes(orderNo)) {
    // 			let product = [pShorthand, pEntry]
    // 			const found = sortedArr.find((e) => e[0] == pShorthand)
    // 			// only put it in the array if its not already inserted by a prior order
    // 			if (!found) {
    // 				subArr.push(product)
    // 			}
    // 		}
    // 	}
    // 	// sort entries per order alphabetically
    // 	subArr.sort()
    // 	sortedArr.push(...subArr)
    // }
    // store.sortedProductQuantities = sortedArr
}

// not called
function setupComponents() {
    const store = Alpine.store("refueat");

    // TODO: denote if vor ort or geliefert

    // everytime we call this function this gets setup again, so we need to reset it in order to not double all amounts
    store.dateComponents = {};
    store.components = [];

    for (const day in store.productQuantities) {
        // skip when we are not on a date of the current daays of the week
        if (!store.daysOfTheWeek.map((a) => a[2]).includes(day)) continue;
        const dayQuantities = store.productQuantities[day];
        if (!store.dateComponents[day]) store.dateComponents[day] = {};
        let dateComponents = store.dateComponents[day];
        for (const shorthand in dayQuantities) {
            const product = dayQuantities[shorthand];
            for (const size in product.totalQtys) {
                const productAmount = product.totalQtys[size];

                // get id to find appropriate components for this product
                const id = store.nameIds[shorthand].find(
                    (ni) => ni[1] == size,
                )[0];

                // get components we stored
                const components = store.productComponents[id]
                    ? store.productComponents[id].components
                    : [];

                // cycle through these components and add totals together
                for (const component of components) {
                    let name = component.component;

                    if (!store.components.includes(name))
                        store.components.push(name);

                    let unit = "";
                    let unitAmount = 0;
                    if (component.qty) {
                        unit = "Stk.";
                        unitAmount = Number(component.qty);
                    } else if (component.grams) {
                        unit = "g";
                        unitAmount = Number(component.grams);
                    } else if (component.liters) {
                        unit = "l";
                        unitAmount = Number(component.liters);
                    }
                    if (!dateComponents[name]) dateComponents[name] = {};

                    if (!dateComponents[name][unit])
                        dateComponents[name][unit] = unitAmount * productAmount;
                    else
                        dateComponents[name][unit] +=
                            unitAmount * productAmount;

                    if (dateComponents[name][unit] == 0)
                        dateComponents[name][unit] = "ja";
                }
            }
        }
    }

    // setup component totals (only for visible week)
    store.componentTotals = {};
    for (const component of store.components) {
        for (const day in store.dateComponents) {
            if (store.daysOfTheWeek.map((d) => d[2]).indexOf(day) < 0) continue;
            if (store.dateComponents[day][component]) {
                for (const unit in store.dateComponents[day][component]) {
                    let amount = store.dateComponents[day][component][unit];
                    if (!store.componentTotals[component])
                        store.componentTotals[component] = {};
                    if (!store.componentTotals[component][unit])
                        store.componentTotals[component][unit] = amount;
                    else
                        store.componentTotals[component][unit] +=
                            amount != "ja" ? amount : "";
                }
            }
        }
    }

    store.components = sortComponents(store.components);
}

/************* HELPERS ***************/

function getTimeFromOrder(order) {
    let time = "";
    if (!!order.meta_data && Array.isArray(order.meta_data)) {
        console.log(order);

        if (order.delivery_type == "delivery") {
            const meta_index = order.meta_data.findIndex(
                (item) => item.key == "delivery_time",
            );
            if (meta_index != -1) time = order.meta_data[meta_index].value;
        } else if (order.delivery_type == "pickup") {
            const meta_index = order.meta_data.findIndex(
                (item) => item.key == "pickup_time",
            );
            if (meta_index != -1) time = order.meta_data[meta_index].value;
        }
    } else {
        console.error("this order has no meta_data");
        console.log(order);
    }

    // in some cases for angebotsanfragen, this isnt set
    if (!time) return "";

    const regex = /^\d{1,2}:\d\d/gm;
    let strippedTime = regex.exec(time)[0];

    if (strippedTime) {
        let hour = Number(strippedTime.substring(0, 2)) - 1;
        let rest = strippedTime.substring(2, 5);
        hour = hour.toLocaleString("de", { minimumIntegerDigits: 2 });

        strippedTime = hour + rest;
    }

    return strippedTime;
}

function getDeliveryNameFromOrder(order) {
    if (order.delivery_type == "delivery") {
        return "Lieferung";
    } else if (order.delivery_type == "pickup") {
        return "Abholung";
    }
}
