// import { supabaseFetch, supabaseRealtimeInit } from "./realtime"
import { processOrders } from "./fetchOrders";
// import { initScrollSpyBlock, scrollToSection } from "./interaction";

/* concepts:
 *
 * orderData {
 * 			"date":
 * 		[
 * 			{
 * 			company: str,
 * 			customerName: str,
 * 			customerNote: str,
 * 			distance: str,
 * 			menus: [{product},],
 * 			orderNumber: str,
 * 			paymentMethod: str,
 * 			phone: str,
 * 			shippingAddress: str,
 * 			shippingMethod: str,
 * 			time: str,
 * 			total: str,
 * 			},
 * 			{...}
 * 		],
 * 			"date": [...],
 * }
 */

document.addEventListener("alpine:init", async () => {
    const store = {
        shops: [
            { name: "Berlin-Schöneberg", url: "refueat.de" },
            { name: "Weisenheim", url: "frankfurt.refueat.de" },
            { name: "NRW", url: "nrw.refueat.de" },
            { name: "DEV", url: "dev.refueat.de" },
        ],
        curShop: Alpine.$persist({
            name: "Berlin-Schöneberg",
            url: "refueat.de",
        }),
        auth: {
            username: "",
            password: "",
            jwts: Alpine.$persist({}),
            showLoginForm: false,
            loading: false,
        },
        doAuth: async function (evt) {
            evt.preventDefault();
            const store = Alpine.store("refueat");

            if (!store.auth.username?.length || !store.auth.password?.length)
                return;

            // get jwt
            const url =
                "https://" + store.curShop.url + "/wp-json/jwt-auth/v1/token";

            try {
                store.auth.loading = true;
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: store.auth.username,
                        password: store.auth.password,
                    }),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const result = await response.json();
                store.auth.jwts[store.curShop.url] = result.token;
                store.auth.loading = false;
                checkAuth();
            } catch (error) {
                store.auth.loading = false;
                console.error("Error:", error);
            }
        },
        lastModified: "",
        previousLastModified: Alpine.$persist(""),
        loading: false,
        showFilters: false,
        showSearch: false,
        showDateInteractions: false,
        dateInteractionsDate: "",
        todayDate: new Date(),
        todayDateISO: new Date().toLocaleDateString("sv"),
        curDate: "",
        curDateISO: "",
        calendarMonthDays: [],
        curMonth: "",
        changeMonth: function (dir) {
            const store = Alpine.store("refueat");

            let change = new Date(store.curDate);
            if (dir == "prev") {
                change.setDate(1); // first of month always exists > 28 not, then it skips a month
                change.setMonth(change.getMonth() - 1);
            } else if (dir == "next") {
                change.setDate(1);
                change.setMonth(change.getMonth() + 1);
            } else {
                change = new Date();
            }
            setTimeout(() => {
                setupCalendarDates(change);
                setTimeout(() => {
                    // hack... because setupCalendarDates also has a short timeout and await didnt work
                    setupScrollSpySections();
                }, 1);
            }, 100);
        },
        scrollSpySections: [],
        handleDayClick: function (day) {
            const store = Alpine.store("refueat");
            let dateISO = day.toLocaleDateString("sv");
            store.selectedDateISO = dateISO;
            scrollToSection(dateISO);
        },
        selectedDateISO: "",
        activeFilters: [],
        maxPrice: "",
        minPrice: "",
        activeType: "alles",
        priceRangeActive: false,
        personnelOrDishIds: Alpine.$persist({}),
        hotOrDrinkIds: Alpine.$persist({}),
        offTimes: {}, // object containing offTimes/offSlots per store
        offTimesFetching: true,

        orderData: {}, // object that contains orders infos like id, time rerferenced by order_date
        // $persist breaks whole app, cause localStorage quota is reached

        filteredOrderData: {}, // object containing dynamic filtered content based on orderData
        currentlyProcessingOrdersFor: null,
        lastRequestFor: {}, //Alpine.$persist({}), // datetime to remember last fetch, to not always fetch again
        fetchedVariations: [],
        hiddenIds: {},
        nameIds: {},
        slotEdit: {
            modalOpen: false,
            isoEdit: "",
            changed: false,
            isSending: false,
        },
    };
    const lastModSplit = document.lastModified.split(" ");
    const d = lastModSplit[0].split("/");
    const t = lastModSplit[1].split(":");

    store.lastModified = `${d[1]}.${d[0]}.${d[2]} ${t[0]}:${t[1]}`;

    if (store.lastModified != store.previousLastModified) {
        // we clear localstorage, as we are dealing with a new version that could include structure changes.
        if (location.hostname != "localhost") {
            for (const item of Object.keys(localStorage)) {
                if (item == "_x_auth.jwts") continue;
                if (item == "_x_curShop") continue;
                localStorage.removeItem(item);
            }
        }
        store.previousLastModified = store.lastModified;
    }

    Alpine.store("refueat", store);

    setInterval(() => {
        // call this every 10 minutes in the background to keep data up to date
        processOrders();
    }, 600000);

    setupCalendarDates(new Date(), true);
    document.getElementById("search").value = "";

    refreshAuth();
    Alpine.effect(() => {
        const store = Alpine.store("refueat");
        const curShop = store.curShop;
        console.log(curShop);
        if (!!curShop.name) {
            checkAuth();
        }
    });
});

document.addEventListener("alpine:initialized", () => {
    setTimeout(() => {
        if ("applyFilters" in window) {
            applyFilters();
        }
    }, 200);

    const curShop = Alpine.store("refueat").curShop;
    if (!!curShop.name) checkAuth();
});

function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(""),
    );

    return JSON.parse(jsonPayload);
}

function checkAuth() {
    console.log("checkAuth");
    const store = Alpine.store("refueat");
    const curJwt = store.auth.jwts[store.curShop.url];
    if (!!curJwt) {
        const authData = parseJwt(curJwt);
        const nowTimestamp = Math.floor(new Date().getTime() / 1000);
        if (nowTimestamp <= authData.exp) {
            console.log("valid auth");
            store.auth.showLoginForm = false;
            store.showLocationSelection = false;
            setTimeout(() => {
                setupOfftimes();
                processOrders().then(() => {
                    setTimeout(() => {
                        // another hack to make things work with scheduler...
                        initScrollSpyBlock();
                    }, 1);
                });
                initScrollSpyBlock();
            }, 100);
            return true;
        } else {
            store.auth.showLoginForm = true;
            store.showLocationSelection = true;
            return false;
        }
    } else {
        store.auth.showLoginForm = true;
        store.showLocationSelection = true;
        return false;
    }
}

async function refreshAuth() {
    const store = Alpine.store("refueat");
    try {
        // for (const shop of store.shops) {
        if (!store.auth.jwts[store.curShop.url]) {
            return;
        }

        const url =
            "https://" +
            store.curShop.url +
            "/wp-json/jwt-auth/v1/token/refresh";

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + store.auth.jwts[store.curShop.url],
            },
        });

        if (!response.ok) {
            // alert(
            //     "Die Anmeldung ist abgelaufen, bitte neu authentifizieren! (Klick oben links auf das refueat Logo.)",
            // );
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const result = await response.json();
        store.auth.jwts[store.curShop.url] = result.token;
        // }
    } catch (error) {
        store.auth.loading = false;
        console.error("Error:", error);
        store.showLocationSelection = true;
    }
}

function setupCalendarDates(date, initial = false) {
    const store = Alpine.store("refueat");

    store.curDate = new Date(date);
    store.curMonth = date.getMonth();

    store.curDateISO = date.toLocaleDateString("sv");
    if (initial) store.selectedDateISO = store.curDateISO;
    store.calendarMonthDays = [];

    // this avoids wrongly displayed dates on month switch in html for some reason
    setTimeout(() => {
        date.setDate(1);
        let firstOfMonth = new Date(date);
        let month = date.getMonth();
        while (date.getMonth() === month) {
            store.calendarMonthDays.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        date.setDate(date.getDate() - 1);

        // pad before first day of the month
        while (firstOfMonth.getDay() != 1) {
            firstOfMonth.setDate(firstOfMonth.getDate() - 1);
            store.calendarMonthDays.unshift(new Date(firstOfMonth));
        }

        // pad after last day of the month
        while (date.getDay() != 0) {
            date.setDate(date.getDate() + 1);
            store.calendarMonthDays.push(new Date(date));
        }
    }, 0);
}

async function setupOfftimes() {
    const store = Alpine.store("refueat");

    console.log(store.calendarMonthDays[0]);

    // fetch off_times
    const data = {
        from: store.calendarMonthDays[0].toLocaleDateString("sv"),
        to: store.calendarMonthDays[
            store.calendarMonthDays.length - 1
        ].toLocaleDateString("sv"),
    };

    const ajaxurl =
        "https://" +
        store.curShop.url +
        "/wp-json/refueat/v1/offtimes?" +
        new URLSearchParams(data).toString();

    try {
        const response = await fetch(ajaxurl, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + store.auth.jwts[store.curShop.url],
            },
        });
        const isSuccessful = response.ok;

        if (isSuccessful) {
            const data = await response.json();
            Alpine.store("refueat").offTimes[store.curShop.url] = data;
            Alpine.store("refueat").offTimesFetching = false;
        }
    } catch (error) {
        Alpine.store("refueat").offTimes[store.curShop.url] = [];
        Alpine.store("refueat").offTimesFetching = false;
    }
}

let isCurrentlyScrolling = false;
let currentActive = -1;

function initScrollSpyBlock() {
    // commented this out because it only causes problems with click interactions
    // document.querySelector("#scroller").addEventListener("scroll", () => {
    //     if (isCurrentlyScrolling) return;
    //     setActiveSection();
    // });

    setupScrollSpySections();
}

function setupScrollSpySections() {
    const store = Alpine.store("refueat");

    currentActive = -1;

    // add all currently displayed days
    store.scrollSpySections = [];
    for (const date of store.calendarMonthDays) {
        const dateISO = date.toLocaleDateString("sv");

        const section = document.querySelector(".group[id='" + dateISO + "']");
        section.isVisible = true;
        store.scrollSpySections.push(section);
    }
}

const scrollToSection = (dateISO, page) => {
    const store = Alpine.store("refueat");

    const index = store.scrollSpySections.findIndex(
        (s) => s.id === dateISO && s.isVisible,
    );
    if (index < 0) return;

    setActiveSection(index);
    isCurrentlyScrolling = true;

    const scroller = document.querySelector("#scroller");
    const scrollFrom = scroller.scrollTop;
    let scrollTo = document.querySelector(
        ".group[id='" + dateISO + "']",
    )?.offsetTop;

    if (scrollTo != 0 && !scrollTo) return;
    scrollTo -= scroller.offsetTop; // important for mobile

    if (scrollFrom != scrollTo) {
        const diff = Math.abs(scrollTo - scrollFrom);
        const duration = parseFloat((Math.log2(diff) / 20).toFixed(2));

        gsap.to("#scroller", {
            duration,
            scrollTo,
            ease: "power2.inOut",
            onComplete: () => {
                isCurrentlyScrolling = false;
            },
        });
    }
};

const setActiveSection = (index) => {
    const store = Alpine.store("refueat");

    let scroller = document.querySelector("#scroller");

    if (typeof index === "undefined") {
        index =
            store.scrollSpySections.length -
            [...store.scrollSpySections]
                .reverse()
                .findIndex(
                    (section) =>
                        scroller.scrollTop >=
                        section?.offsetTop - scroller.offsetTop - 100,
                ) -
            1;
    }

    if (index >= 0 && index !== currentActive) {
        currentActive = index;
        store.selectedDateISO = store.scrollSpySections[index]?.id;
    }
};

window.rangeSlider = null;

window.onload = function () {
    const store = Alpine.store("refueat");
    console.log("on load!");
    // get min and max price of all orders
    let minPrice = 1000;
    let maxPrice = 0;
    for (const date in store.orderData[store.curShop.url]) {
        for (const entry of store.orderData[store.curShop.url][date]) {
            if (entry.totalValue > maxPrice) maxPrice = entry.totalValue;
            if (entry.totalValue < minPrice) minPrice = entry.totalValue;
        }
    }
    store.maxPrice = maxPrice;
    store.minPrice = minPrice;
    applyFilters();

    // window.rangeSlider = document.getElementById("slider-range");

    // noUiSlider.create(window.rangeSlider, {
    //     start: [Math.floor(minPrice), Number(Math.ceil(maxPrice))],
    //     connect: true,
    //     range: {
    //         min: Math.floor(minPrice),
    //         max: Number(Math.ceil(maxPrice)),
    //         "20%": 200,
    //         "80%": maxPrice > 5000 ? 5000 : maxPrice,
    //     },
    //     step: 1,
    // });

    // window.rangeSlider.noUiSlider.on("update", function () {
    //     let values = window.rangeSlider.noUiSlider.get();
    //     store.maxPrice = values[1];
    //     store.minPrice = values[0];
    //     if (
    //         Number(values[0]) != Math.floor(minPrice) ||
    //         Number(values[1]) != Number(Math.ceil(maxPrice))
    //     ) {
    //         store.priceRangeActive = true;
    //     } else {
    //         store.priceRangeActive = false;
    //     }
    // });

    // window.rangeSlider.noUiSlider.on("set", function () {
    //     applyFilters();
    // });
};
